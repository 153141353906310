import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GlobalLoader from './components/GlobalLoader';
import Loader from './components/Loader';
const LoginPage = lazy(() => import('./pages/LoginPage'));
const EmployeeRoute = lazy(() => import('./routes/EmployeeRoute'));
const AdminRoute = lazy(() => import('./routes/AdminRoute'));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='login' element={<LoginPage />} />
        {/* INFO: employee route is used for EMPLOYEE and PARTNER_EMPLOYEE role */}
        <Route path='employee/*' element={<EmployeeRoute />} />
        {/* INFO: admin route is used for ADMIN and PARTNER_ADMIN role */}
        <Route path='admin/*' element={<AdminRoute />} />
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
      <GlobalLoader />
    </Suspense>
  );
}

export default App;
