import { Theme } from "@mui/system";

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "capitalize" as const,
          fontWeight: 500,
          fontSize: theme.spacing(1.75),
        },
        containedPrimary: {
          color: "#ffffff",
          // backgroundColor: theme.palette.primary.dark,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
          "&:active": {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
  };
};

export default Button;
