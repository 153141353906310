const Table = () => {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.MuiTableCell-head': {
            fontWeight: 600,
          },
        },
      },
    },
  };
};

export default Table;
