const ListItemIcon = () => {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#161546",
        },
      },
    },
  };
};

export default ListItemIcon;
