import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { ReactNode } from 'react';

export const SnackbarContext = React.createContext<{
  showMessage: (message: string) => void;
  showErrorMessage: (message: string) => void;
  showSuccessMessage: (message: string) => void;
  showWarningMessage: (message: string) => void;
}>({
  showMessage: () => {},
  showErrorMessage: () => {},
  showSuccessMessage: () => {},
  showWarningMessage: () => {},
});

export default function SnackbarProvider({ children }: { children: ReactNode }) {
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [kind, setKind] = React.useState<AlertColor>('info');

  const showMessage = (message: string) => {
    setMessage(message);
    setOpen(true);
  };

  const showSuccessMessage = (message: string) => {
    setMessage(message);
    setKind('success');
    setOpen(true);
  };

  const showErrorMessage = (message: string) => {
    setMessage(message);
    setKind('error');
    setOpen(true);
  };

  const showWarningMessage = (message: string) => {
    setMessage(message);
    setKind('warning');
    setOpen(true);
  };

  const handleReset = () => {
    setOpen(false);
    setKind('info');
    setMessage('');
  };

  return (
    <>
      <SnackbarContext.Provider
        value={{
          showMessage,
          showErrorMessage,
          showSuccessMessage,
          showWarningMessage,
        }}
      >
        {children}
      </SnackbarContext.Provider>

      <Snackbar
        onClose={handleReset}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        open={open}
      >
        <Alert variant='filled' severity={kind}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
