import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { useIsMutating } from 'react-query';
import css from '../styles/GlobalLoader.module.css';

const GlobalLoader = () => {
  const isMutating = useIsMutating();
  const isLoading = !!isMutating;

  return isLoading ? (
    <Backdrop open={true}>
      <Paper className={css['loader-backdrop-indicator']}>
        <CircularProgress color='inherit' size={25} />
      </Paper>
    </Backdrop>
  ) : (
    <div></div>
  );
};

export default GlobalLoader;
