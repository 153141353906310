const PRIMARY = {
  main: '#5273E8',
};

const SECONDARY = {
  light: "#E4BB3F",
  main: "#DEAA10",
  dark: "#9B760B",
};
const INFO = {
  light: "#7F9FAD",
  main: "#608899",
  dark: "#435F6B",
};
const SUCCESS = {
  light: "#AAF27F",
  main: "#348958",
  dark: "#229A16",
};

const ERROR = {
  light: "#E4713F",
  main: "#F15A38",
  dark: "#9B360B",
};

const GREY = {
  background: "#F3F4FF",
  light: "#FAFAFA",
};

export const COMMON = {
  black: "#272727",
  white: "#FFFFFF",
  link: "#3A21D3",
  begonia: "#FA6F6F",
  limeGreen: "#33C341",
  majorelleBlue: "#5D6AE5",
  goldFoil: "#BF9709",
  vividMulberry: "#C71EF1",
  brilliantRose: "#F35D9E",
  cornflowerBlue: "#5F96EE",
  etonBlue: "#8FBAA0",
  celadon: "#BAE7C1",
};

const palette = {
  common: { ...COMMON },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...ERROR },
  error: { ...ERROR },
  grey: GREY,
  text: {
    primary: "#343447",
    secondary: "#73738D",
    // disabled: GREY[500]
  },
  background: {
    paper: "#FFFFFF",
    // default: "#E5E5E5",
  },
  action: {
    // disabledBackground: GREY[500],
    // disabled: GREY[500],
  },
};

export { palette };
